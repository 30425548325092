import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { FaFacebookSquare, FaInstagram } from "react-icons/fa"
import BackgroundImage from "gatsby-background-image"

const Sidebar = () => {
  const sidebarData = useStaticQuery(
    graphql`
      query {
        sidebarLogo: file(relativePath: { eq: "full-moon-white-500w.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 250) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        sidebarSchedule: file(
          relativePath: { eq: "gold-bikini-woman-sidebar.jpg" }
        ) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        sidebarWoman: file(
          relativePath: { eq: "pulling-bikini-bottom-sidebar.jpg" }
        ) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        sidebarFaq: file(relativePath: { eq: "faq-sidebar.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        sidebarMen: file(relativePath: { eq: "man-back-sidebar.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <div className="sidebar p-3">
      <div className="sidebar-logo pb-3">
        <Img fluid={sidebarData.sidebarLogo.childImageSharp.fluid} />
      </div>
      <div className="sidebar-contact pb-3">
        <hr />
        <span className="sidebar-phone">(919) 623-0361</span>
        <span className="sidebar-address">
          1065 Bullard Ct., 2nd Floor, Raleigh, NC 27615
        </span>
        <div className="sidebar-icons">
          <Link to="/page-2" className="mx-3">
            <FaFacebookSquare />
          </Link>
          <Link to="/page-2" className="mx-3">
            <FaInstagram />
          </Link>
        </div>
        <hr />
        <span className="sidebar-hours">Tues 11:30am - 5:00pm</span>
        <span className="sidebar-hours">Wed-Thur 10:30am - 7:00pm</span>
        <span className="sidebar-hours">Fri 10:30am - 5:00pm</span>
        <span className="sidebar-hours">Sat 1:00pm - 4:00pm</span>
        <hr />
      </div>

      <BackgroundImage
        Tag="div"
        className="sidebar-promo mb-3"
        fluid={sidebarData.sidebarSchedule.childImageSharp.fluid}
      >
        <Link to="/skincare">
          <div className="sidebar-promo-overlay">
            <div className="sidebar-promo-content py-1">
              <h4>Skincare for All</h4>
            </div>
          </div>
        </Link>
      </BackgroundImage>
      <BackgroundImage
        Tag="div"
        className="sidebar-promo mb-3"
        fluid={sidebarData.sidebarWoman.childImageSharp.fluid}
      >
        <Link to="/waxing-services-for-women">
          <div className="sidebar-promo-overlay">
            <div className="sidebar-promo-content py-1">
              <h4>Waxing for Women</h4>
            </div>
          </div>
        </Link>
      </BackgroundImage>
      <BackgroundImage
        Tag="div"
        className="sidebar-promo mb-3"
        fluid={sidebarData.sidebarMen.childImageSharp.fluid}
      >
        <Link to="/waxing-services-for-men">
          <div className="sidebar-promo-overlay">
            <div className="sidebar-promo-content py-1">
              <h4>Waxing for Men</h4>
            </div>
          </div>
        </Link>
      </BackgroundImage>
      <BackgroundImage
        Tag="div"
        className="sidebar-promo mb-3"
        fluid={sidebarData.sidebarFaq.childImageSharp.fluid}
      >
        <Link to="/faq">
          <div className="sidebar-promo-overlay">
            <div className="sidebar-promo-content py-1">
              <h4>Frequent Questions</h4>
            </div>
          </div>
        </Link>
      </BackgroundImage>
    </div>
  )
}

export default Sidebar
